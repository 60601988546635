<template>
  <section class="min-w1600">
     <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <button type="button" class="btn-layout btn-grey" @click="detailModalOpen('c')">{{ $t('common.bannerAdd') }}</button>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <h4 class="tableName">{{ $t('txt.bannerEx') }}<!--em>{{ $t('txt.setBanner') }}</em--></h4>
        <!--div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">0</span>
          </div>
        </div> -->
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="tableData.length !== 0">
              <tr v-for="(item, idx) in tableData" :key="idx">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>
                  <template v-if="item.bannerType === 'P'">
                    {{ $t('common.popup') }}
                  </template>
                  <template v-else>
                    {{ $t('common.banner') }}
                  </template>
                  ({{ item.bannerPosition }})
                </td>
                <td>
                  <template v-if="item.loginYn === 'Y'">
                    {{ $t('common.print') }}
                  </template>
                  <template v-else>
                    {{ $t('common.all') }}
                  </template>
                </td>
                <td>{{item.bannerTitle}}</td>
                <td>
                   <a @click="item.bannerImage = !item.bannerImage" v-if="item.bannerImg"><img :src="item.bannerImg" class="tableInnerImg" /></a>
                   <span v-else>-</span>
                   <div v-if="item.bannerImage" class="bannerImg">
                      <a class="close" @click="item.bannerImage = false"><i class="fa fa-times" aria-hidden="true"></i></a>
                      <img :src="item.bannerImg" v-if="item.bannerImg" />
                   </div>
                </td>
                <td>{{ item.domain }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="detailModalOpen('u', item)">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="delBanner(item)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!--pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" /-->
    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3 v-if="modalType === 'u'">{{ $t('button.modify') }}</h3>
            <h3 v-if="modalType === 'c'">{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.choosetype') }}</div>
                <div class="item-content">
                  <select v-if="modalType === 'c'" v-model="saveData.bannerType">
                    <option value="B">{{ $t('common.banner') }}</option>
                    <option value="P">{{ $t('common.popup') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.afLogin') }} {{ $t('common.print') }}</div>
                <div class="item-content">
                  <select v-model="saveData.loginYn">
                    <option value="Y">{{ $t('common.afLogin') }}</option>
                    <option value="N">{{ $t('common.all') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.title') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.bannerTitle" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.attIMG') }}</div>
                <div class="item-content">
                  <input type="file" @change="readFile" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.insert') }}</div>
                <div class="item-content">
                  <textarea v-model="saveData.bannerContent"></textarea>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.location') }}</div>
                <div class="item-content">
                  <select v-model="saveData.bannerPosition">
                    <template v-if="saveData.bannerType === 'B'">
                      <option v-for="item of bPosition" :key="item.code">{{ item.code }}</option>
                    </template>
                    <template v-if="saveData.bannerType === 'P'">
                      <option v-for="item of pPosition" :key="item.code">{{ item.code }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.site') }}</div>
                <div class="item-content">
                  <input type="text" v-model="saveData.domain" />
                </div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="updateBanner" v-if="modalType === 'u'">{{ $t('button.save') }}</a>
              <a @click="saveBanner" v-if="modalType === 'c'">{{ $t('button.save') }}</a>
              <a @click="modalClose()">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import typing from '@/components/ui/Typing'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import { getCode } from '@/api/common'
import { getSiteData } from '@/libs/auth-helper'
import { frontBanner, frontBannerSave } from '@/api/member.js'
import Memo from '@/components/common/memo'
export default {
  name: 'SiteFronBanner',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing,
    Memo
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'type', 'afterLogin', 'title', 'bannerimg', 'site', 'type']
      },
      reqData: {
        page: 1,
        count_per_list: 30
      },
      tableData: [],
      modalType: '',
      saveData: {
        siteId: '',
        bannerType: 'B',
        bannerImg: '',
        domain: '',
        bannerPosition: '',
        bannerContent: ''
      },
      pageInfo: {
        page: 0,
        count_per_list: 0,
        tatal_list_count: 0
      },
      pPosition: [],
      bPosition: [],
      updModalActive: false,
      bannerImage: false
    }
  },
  methods: {
    async getPosition () {
      const pPosition = await getCode('pPosition')
      const bPosition = await getCode('bPosition')
      this.pPosition = pPosition
      this.bPosition = bPosition
    },
    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async readFile (e) {
      const target = e.target
      const base64 = await this.toBase64(target.files[0])
      this.saveData.bannerImg = base64
    },
    async saveBanner () {
      const siteInfo = this.getSiteInfo()
      const req = this.saveData
      req.delYn = 'N'
      req.siteId = siteInfo.siteId
      if (!req.bannerTitle) {
        alert('타이틀을 입력해주세요')
        return
      }

      if (!req.bannerImg) {
        alert('이미지를 업로드해주세요')
        return
      }

      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }

      const res = await frontBannerSave(req)
      if (res.resultCode === '0') {
        alert('추가 완료')
        this.setTableData()
        this.modalClose()
        this.saveData = {
          bannerType: '',
          bannerImg: '',
          bannerTitle: '',
          domain: '',
          bannerPosition: ''
        }
      } else {
        alert('추가 실패, 다시 시도해주세요.')
      }
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
    },
    async updateBanner () {
      const siteInfo = this.getSiteInfo()
      const req = lodash.cloneDeep(this.saveData)
      req.siteId = siteInfo.siteId

      if (!req.bannerTitle) {
        alert('타이틀을 입력해주세요')
        return
      }

      if (!req.bannerImg) {
        alert('이미지를 업로드해주세요')
        return
      }

      const trigger = confirm('해당 게시물을 수정 하시겠습니까?')
      if (!trigger) {
        return
      }

      console.log(req)
      const res = await frontBannerSave(req)
      if (res.resultCode === '0') {
        alert('수정 완료')
        this.setTableData()
        this.modalClose()
        this.saveData = {
          bannerImg: '',
          domain: '',
          bannerPosition: '',
          bannerTitle: ''
        }
      } else {
        alert('수정 실패, 다시 시도해주세요.')
      }
    },
    async delBanner (banner) {
      const siteInfo = this.getSiteInfo()
      const trigger = confirm('해당 게시물을 삭제 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = banner
      req.delYn = 'Y'
      const res = await frontBannerSave(req)
      if (res.resultCode === '0') {
        alert('삭제 완료')
        this.setTableData()
        this.modalClose()
        this.saveData = {
          bannerImg: '',
          domain: '',
          bannerPosition: '',
          bannerTitle: ''
        }
      } else {
        alert('삭제 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    detailModalOpen (type, item) {
      this.modalType = type
      console.log(type)
      if (type === 'c') {
        this.saveData = {}
      } else if (type === 'u') {
        for (const key in item) {
          this.saveData[key] = item[key]
        }
        console.log(this.saveData)
      }
      this.updModalActive = true
    },
    async setTableData () {
      this.emitter.emit('Loading', true)
      const req = { }
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await frontBanner(req)
      this.emitter.emit('Loading', false)
      this.tableData = res.data.list
    }
  },
  async created () {
    await this.getPosition()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.total-info-container {}
.tableName {margin-right: auto;font-size: 16px;color: #616161;}
.tableName em {font-size: 14px;color: #d93832;margin-left: 10px;}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
   width: 100%;
   min-height: 160px;
   border: 1px solid #aaa;
   resize: none;
   box-sizing: border-box;
   padding: 10px;
   border-radius: 2px;
   background-color: #fafafa;
}
.modal-wrapper {
  display: flex;
}
.item-wrap > div.item-title {
  min-width: 110px;
}
.tableInnerImg {max-height: 100px;margin: 0 auto;}
.bannerImg {position: absolute;left: 50%;top: 50%;width: 550px;transform: translate(-50%, -50%);box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #c1c1c1;background-color: #fafafa;padding: 15px 20px;box-sizing: border-box;}
.bannerImg img {width: 100%;}
.bannerImg .close {font-size: 20px;color: #646464;display: flex;justify-content: end;padding-bottom: 15px;}
</style>
